<template>
    <div class=" py-5">

        <div class="card mx-auto" style="background:#1E1E1E;width: 90%;border-radius: 20px;">
            <div class="card-body mx-md-5 my-2">
                    <h1 class="fw-bold mb-5 text-center">Summon Fighters</h1>

                <div style="background: url(images/bg_summon.svg) no-repeat;background-position: center;
  background-repeat: no-repeat;
  background-size: cover;border-radius: 50px;
" class="p-md-5">
                    <div class="row mx-5 px-5">
                        <div class="col-md-4 d-none d-md-block">
                            <img src="/images/light.svg" class="image-rotation" style="width:100%;z-index:1 !important;position: relative">
                            <img src="/images/fnf_soul.png" style="margin-top:-100%;width: 50%;z-index:4 !important;position: relative; margin-left: 15%;" >
                        </div>
                        <div class="col-md-4 d-none d-md-block">
                            <center><h1 style="font-size: 50px; margin-top: 200px;">=</h1></center>
                        </div>
                        <div class="col-md-4 d-none d-md-block">
                            <img src="/images/light.svg" class="image-rotation" style="width:100%;z-index:1 !important;position: relative">
                            <img src="/images/random_char.png" style="margin-top:-110%;width: 30%;z-index:4 !important;position: relative; margin-left: 37%;" >
                        </div>
                        <div class="col-12 d-block d-md-none">
                            <img src="/images/light.svg" class="image-rotation" style="width:300%;z-index:1 !important;position: relative;margin-left: -100%">
                            <img src="/images/random_char.png" style="margin-top:-350%;width: 100%;z-index:4 !important;position: relative; margin-left: 5%;" >
                        </div>
                    </div>
                    
                    <div style="background: #59180C;border-radius: 50px;" class="d-block m-md-5 p-5 px-3">
                        
                        <div class="row mt-2">
                            
                            <div class="col-md-4 col-6 text-center">
                                <h5 class="fw-bold mt-3">My Soul Token</h5>
                                <h1 class="fw-bold">-</h1>
                            </div>
                            <div class="col-md-4 col-6 text-center">
                                <h5 class="fw-bold mt-3">My FNF</h5>
                                <h1 class="fw-bold">-</h1>
                            </div>
                            <div class="col-md-4 ">
                                <center>
                                    <small class="ms-3 mt-2 text-muted ">-- FnF + -- Soul</small>

                                    <button style="background: transparent; border: none;"><img src="/images/button_summon.png" style="height: 70%;width: 70%;"></button>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Dashboard',
        components: {
            // HelloWorld
        },
        data: () => ({
            filter: '24'
        }),
        methods: {

        },
        mounted() {
            document.title = "Dashboard | FarmAndFight"

        }
    }
</script>
<style scoped>
    .image-rotation {
  /* position: absolute; */
/* 
  height: 120px;
  margin:-60px 0 0 -60px; */
  -webkit-animation:spin 10s linear infinite;
  -moz-animation:spin 10s linear infinite;
  animation:spin 10s linear infinite;
}
@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}
</style>