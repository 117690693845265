<template>
  <div class="marketplace  pt-5">
    <div class="card mx-auto" style="background:#1E1E1E;width: 90%;border-radius: 20px;">
      <div class="card-body mx-md-5 mt-3 my-md-5">
                <h1 class="text-white mb-5">Marketplace</h1>

        <div style="  overflow: auto;
  white-space: nowrap;">
          <button class="btn btn-fighter px-4 me-3" :class="{'btn-fighter-active':filterMarket =='fighter'}"
            @click="filterMarket='fighter'"><img class="me-3" src="images/icon_figther.svg">Fighter</button>
          <button class="btn btn-fighter px-4 me-3" :class="{'btn-fighter-active':filterMarket =='items'}"
            @click="filterMarket='items'" style="padding-top:14px;padding-bottom:14px;">
            <img class="me-3" src="images/items.svg">
            Items</button>
          <button class="btn btn-fighter px-4 me-3" :class="{'btn-fighter-active':filterMarket =='land'}"
            @click="filterMarket='land'"><img class="me-3" src="images/icon_figther.svg">Land</button>
          <button class="btn btn-fighter px-4" :class="{'btn-fighter-active':filterMarket =='landItems'}"
            @click="filterMarket='landItems'"><img class="me-3" src="images/icon_figther.svg">Land items</button>
        </div>
 
        <div class="row mt-5">
          <div class="col-md-3">
            <div class="card card-filter">
              <p class="text-center" style="font-size:3.5em;margin-top:20vh">Coming Soon</p>

            </div>
          </div>
          <div class="col">
                   <div class="float-end">
          <select class="btn btn-recently mt-5 mt-md-0">
            <option>Recently Listed</option>
            <option>Lowest Price</option>
            <option>Highest Price</option>
          </select>
        </div>
            <p class="text-center" style="font-size:3.5em;margin-top:20vh">Coming Soon</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'

  export default {
    name: 'Home',
    components: {
      // HelloWorld
    },
    data: () => ({
      filterMarket: 'fighter'
    }),
    mounted() {
      document.title = "Marketplace | FarmAndFight"

    }

  }
</script>
<style scoped>
  .btn {
    font-weight: bold;
    border-radius: 30px;
    font-size: 2.8vh;
  }

  .btn-market {
    background: #50E91A;
    color: #353333;
  }

  .card-filter {
    background: #42210B;
    height: 70vh;
    border-radius: 30px;
    border: 4px solid #FFBE2A;
  }

  .btn-fighter {
    background: #5E5E5E;
    color: white;
    border-radius: 12px !important;
  }

  .btn-fighter-active {
    background: #50E91A !important;
    color: #353333 !important;
  }

  .btn-mystery {
    background: #515151;
    color: white
  }

  .btn-recently {
    background: #D30303;
    color: white
  }
</style>