import { createRouter, createWebHistory } from 'vue-router'
import Marketplace from '../views/Marketplace.vue'
import Dashboard from '../views/Dashboard.vue'
import Profile from '../views/Profile.vue'
import Summon from '../views/Summon.vue'
import Mint from '../views/Mint.vue'

const routes = [
  {
    path: '/',
    name: 'Mint',
    component: Mint
  },
  {
    path: '/marketplace',
    name: 'Markeplace',
    component: Marketplace
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/summon',
    name: 'Summon',
    component: Summon
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
