<template>
    <div class="py-5">
      
        <div class="card mx-auto" style="background:#1E1E1E;width: 90%;border-radius: 20px;">
  <div class="card-body mx-md-5 mt-3 my-3">
                    <h1 class="text-white mb-5">Dashboard</h1>
  <div style="  overflow: auto;
  white-space: nowrap;">
    <button class="btn btn-cust btn-non px-4 me-4" @click="this.filter='24'" :class="{ 'btn-active':filter == '24' }"><b>24 Hours</b></button>
    <button class="btn btn-cust btn-non px-4  me-4" @click="this.filter='7'"  :class="{ 'btn-active':filter == '7' }"><b>7 Days</b></button>
    <button class="btn btn-cust btn-non px-4" @click="this.filter='30'"  :class="{ 'btn-active':filter == '30' }"><b>30 Days</b></button>
  </div>
 <div class="card mx-auto mt-5" style="background:#734910;">
       <div class="card-body mx-md-5">
           <div class="row">
               <div class="col-md-4 d-inline-flex">
                   <img src="images/chart.svg" width="20%">
                      <div class="ms-3 mt-3">
                       TOTAL SALE
                       <h2>-</h2>
                       </div>
               </div>
               <div class="col-md-4 d-inline-flex">
                   <img src="images/polygon.svg" width="20%">
                   <div class="ms-3 mt-3">
                       TOTAL VOLUME
                       <h2>-</h2>
                       </div>
               </div>
               <div class="col d-inline-flex">
                   <img src="images/fnf.svg" width="20%">
                <div class="ms-3 mt-3">
                   FNF SOLD
                   <h2>-</h2>
                       </div>
               </div>
           </div>


           
       </div>
       </div>
                  <div class="row mt-5">
             <div class="col-md-6 mb-5">
               <h4 class="text-white fw-bold">Recently Listed</h4>
               <h6>Coming Soon</h6>
             </div>
             <div class="col-md-6">
               <h4 class="text-white fw-bold">Recently Sold</h4>
               <h6>Coming Soon</h6>
             </div>
           </div>
  </div>
</div>
    </div>
</template>
<script>


export default {
  name: 'Dashboard',
  components: {
    // HelloWorld
  },
   data: () => ({
        filter: '24'
        }),
  methods: {
      
  },
  mounted() {
        document.title = "Dashboard | FarmAndFight"

  }
}
</script>
<style scoped>
.btn-cust{
  border-radius: 10px!important;
  font-size:1.5em;
}
.btn-active{
  background:#50E91A !important;
  color:#353333 !important;
}
.btn-non{
  background:#5E5E5E;
  color:white;
}
</style>
