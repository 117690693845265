<template>
  <div class=" py-5">
    <div class="card mx-auto" style="background:#1E1E1E;width: 90%;border-radius: 20px;">
      <div class="card-body mx-md-5 mt-3">
        <h1 class="text-white">My Profile {{ $parent.address }}</h1>

        <div class="card mx-auto mt-5" style="background:#734910;">
          <div class="card-body mx-md-5">
            <div class="row">
              <div class="col-md-3 d-inline-flex mb-1">
                <img src="images/fnf.svg" height="64" width="64">
                <div class="ms-3 mt-1">
                  My FNF
                  <h2>-</h2>
                </div>
              </div>
              <div class="col-md-3 d-inline-flex mb-1">
                <img src="images/Ore.png" height="64" width="64">
                <div class="ms-3 mt-1">
                  My Ore
                  <h2>-</h2>
                </div>
              </div>
              <div class="col-md-3 d-inline-flex mb-1">
                <img src="images/Wood.png" height="64" width="64">
                <div class="ms-3 mt-1">
                  My Wood
                  <h2>-</h2>
                </div>
              </div>
              <div class="col-md-3 d-inline-flex mb-1">
                <img src="images/Food.png" height="64" width="64">
                <div class="ms-3 mt-1">
                  My Food
                  <h2>-</h2>
                </div>
              </div>

            </div>



          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-12" style="  overflow: auto;
  white-space: nowrap;">
            <button class="btn btn-cust btn-non px-4 me-4" @click="this.filter='fighters'"
              :class="{ 'btn-active':filter == 'fighters' }"><b>Fighters</b></button>
            <button class="btn btn-cust btn-non px-4  me-4" @click="this.filter='tools'"
              :class="{ 'btn-active':filter == 'tools' }"><b>Tools</b></button>
            <button class="btn btn-cust btn-non px-4 me-4" @click="this.filter='lands'"
              :class="{ 'btn-active':filter == 'lands' }"><b>Lands</b></button>

          </div>
          <div class="col-md-12 mt-5">
            <h4 class="text-white">Coming Soon</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Dashboard',
    components: {
      // HelloWorld
    },
    data: () => ({
      filter: 'fighters'
    }),
    methods: {

    },
    mounted() {
      document.title = "My Profile | FarmAndFight"
      console.log(this.$parent.$data)

    }
  }
</script>
<style scoped>
  .btn-cust {
    border-radius: 10px !important;
    font-size: 1.5em;
  }

  .btn-active {
    background: #50E91A !important;
    color: #353333 !important;
  }

  .btn-non {
    background: #5E5E5E;
    color: white;
  }
</style>