<template>
    <div>
  <!-- <div class="relative overflow-hidden rounded-lg shadow-lg cursor-pointer">
      <img class="object-cover w-full h-full" src="images/mintBox.svg" alt="Flower and sky"/><div class="absolute top-0 left-0 px-6 py-4">
      <h4 class="mb-3 text-xl font-semibold tracking-tight text-white">This is the title</h4>
<p class="leading-normal text-gray-100">Lorem ipsum dolor, sit amet cons ectetur adipis icing elit. Praesen tium, quibusdam facere quo laborum maiores sequi nam tenetur laud.</p>
	</div>
  </div> -->
        <div v-if="!open">
            <!-- <img src="images/bg_home.svg" style="z-index: -1;object-fit: cover;" class="absolute w-full lg:h-auto bg-cover min-h-screen"> -->
            <div class="lg:grid lg:grid-cols-2 md:px-0 px-3 pt-5">
                <img class="place-self-center lg:w-8/12 w-full" src="images/logoFarmMint.svg">
                <div class="relative place-self-center card lg:mb-0 mb-5 lg:mt-0 mt-9 bg-transparent border-0 lg:w-9/12 w-full">
                    <img src="images/mintBox.svg" class="card-img-top xl:inline lg:hidden md:inline hidden">
                    <img src="images/mintBox2.svg" class="card-img-top xl:hidden lg:inline md:hidden">
                    <div class="text-black absolute 2xl:text-xl xl:text-base lg:text-lg sm:text-2xl text-sm card-body 2xl:px-24 lg:px-10 md:px-24 px-10 2xl:pt-14vh md:pt-12vh pt-10vh">
                        First 5000 character, will be get something special
                        its effect.
                        <br>
                        <br>
                        The character will be get random effect thats make
                        being fast farm ore,stone,or tree, the effect can be
                        a rarerity
                        <br>
                        <br>
                        Start for 1% couldown reduction - 10% couldown
                        reduction
                        <br>
                        <br>
                        Its only 5000 character first if you will be summon
                        later you can’t get anything effect<br>
                    </div>
                        <center class="absolute 2xl:bottom-16 lg:bottom-10 sm:bottom-20 bottom-10  md:px-20 px-10 w-full">
                    <button class="bg-pink1 2xl:w-11/12 w-10/12 font-bold border-0 2xl:text-3xl xl:text-lg lg:text-base sm:text-3xl text-sm 
                    md:py-3 sm:py-2 py-3 
                    text-white rounded-full" @click="toMint">
                        Get Now !
                    </button>
                        </center>
                </div>
            </div>
        </div>
        <div class="pb-5 bgMint" v-if="open" style="min-height: 100vh;">
            <video v-if="scene == true" class="w-full" ref="videoOpen" src="/videos/openingBox.mp4"></video>
            <video v-if="scene == false" class="w-full" ref="videoMint" autoplay loop
                src="/videos/autoplay.mp4"></video>
            <div v-if="scene == false" class="card cardMint rounded-3xl mx-auto md:mt-0 mt-10 md:py-10 w-4/5" >
                <div class="card-body md:px-24 ">
                    <div class="grid grid-cols-2">
                    <div class="font-bold md:text-3xl self-center ">Cost : {{cost}} ETH</div>
                    <a class="ml-auto btnMint" @click="mint"><img src="/images/btnMint.png" class="md:w-48 w-28"></a>
                        </div>
                </div>
            </div>
            <img class="place-self-center px-5 pt-40 md:w-8/12 w-full md:hidden" src="images/logoFarmMint.svg">
            <div class="modal" id="showCharacter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered " role="document">
                    <div class="modal-content bg-transparent border-0">

                        <div class="modal-body">
                            <img src="Images/light.svg" class="image-rotation"
                                style="margin-top:-20%;width:100%;z-index:1 !important;position: relative">
                            <center>
                                <img :src="info.image"
                                    style="margin-top:-100%;width: 54%;z-index:4 !important;position: relative">
                            </center>
                            <div class="2xl:text-5xl font-bold text-center -mt-20">{{ info.name }}</div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style>
html{
    background: url("../../public/images/bg_home.svg");
    @apply lg:h-full;
    overflow-x:hidden;
    background-repeat: no-repeat;
    background-size: cover;
}
body{
    background: transparent;
}
    .cardMint {
        background: #2C1012;
    }

    .btnMint {
        cursor: pointer
    }
    .image-rotation {
        -webkit-animation: spin 10s linear infinite;
        -moz-animation: spin 10s linear infinite;
        animation: spin 10s linear infinite;
    }
    .card-img-top {
    @apply md:w-full md:h-full;
    object-fit: contain;
    position: relative;
    }
</style>
<script>
    const ABI_MINT = [{
            "anonymous": false,
            "inputs": [{
                    "indexed": false,
                    "internalType": "address",
                    "name": "minter",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "tokenId",
                    "type": "uint256"
                }
            ],
            "name": "Minting",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [{
                    "indexed": true,
                    "internalType": "address",
                    "name": "previousOwner",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "newOwner",
                    "type": "address"
                }
            ],
            "name": "OwnershipTransferred",
            "type": "event"
        },
        {
            "inputs": [],
            "name": "_mintingLimit",
            "outputs": [{
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "_mintingPrice",
            "outputs": [{
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "owner",
            "outputs": [{
                "internalType": "address",
                "name": "",
                "type": "address"
            }],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "renounceOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [{
                "internalType": "address",
                "name": "newOwner",
                "type": "address"
            }],
            "name": "transferOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "mint",
            "outputs": [{
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }],
            "stateMutability": "payable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "withdraw",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [{
                "internalType": "address",
                "name": "character",
                "type": "address"
            }],
            "name": "setCharacter",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [{
                    "internalType": "uint256",
                    "name": "mintingLimit",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "mintingPrice",
                    "type": "uint256"
                }
            ],
            "name": "setMintInfo",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        }
    ]
    export default {
        data() {
            return {
                video: this.$refs.videoMint,
                videoScene: this.$refs.videoOpen,
                scene: false,
                minter: null,
                cost: 0,
                info: {},
                open: false,
            }
        },
        methods: {
            async checkInfo(id) {
                try {
                    let res = await fetch(`https://nft.farmandfight.io/${id}.json`)
                    let json = await res.json()
                    if (json['error'] == undefined) {
                        this.info = json
                        this.playScene()
                        return;
                    }
                    setTimeout(this.checkInfo.bind(this), 1000)
                } catch (error) {
                    console.log(error)
                }
            },
            async toMint(){
                if(this.$parent.$parent.address === null){
                    await this.$parent.$parent.connectMetamask();
                    this.open = true
                }
                this.open = true

            },
            async mint() {
                let that = this
                let minter = new this.$parent.$parent.web3.eth.Contract(ABI_MINT,
                    "0x22716221e4eb18152e792F5341370E90E5472B11");
                let cost = await minter.methods._mintingPrice().call()

                await minter.methods.mint().send({
                    from: this.$parent.$parent.address,
                    value: cost
                }).on('receipt', (receipt) => {
                    console.log(receipt)
                    that.checkInfo(receipt.events['Minting']['returnValues']['tokenId'])
                })
            },
            async playScene() {
                let that = this
                this.scene = true;
                await this.$nextTick()
                this.$refs.videoOpen.volume = 0.1
                this.$refs.videoOpen.play()
                this.$refs.videoOpen.onended = function () {
                    that.scene = false
                    that.playMint()
                    var myModal = new window.bootstrap.Modal(document.getElementById('showCharacter'), {
                        keyboard: false
                    })
                    myModal.show()
                };
            },
            async playMint() {
                await this.$nextTick()
                this.$refs.videoMint.volume = 0.1
                this.$refs.videoMint.play()
            },
            async getData() {
                if (this.$parent.$parent.web3 == null) {
                    setTimeout(this.getData.bind(this), 100);
                    return;
                }
                this.minter = new this.$parent.$parent.web3.eth.Contract(ABI_MINT,
                    "0x22716221e4eb18152e792F5341370E90E5472B11");
                let cost = await this.minter.methods._mintingPrice().call()
                this.cost =  this.$parent.$parent.web3.utils.fromWei(cost)
            }
        },
        mounted() {
            this.getData()
        },
    }
</script>