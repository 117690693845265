<template>
<div>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link>
  </div> -->
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid mx-md-5 px-md-5">
      <router-link class="navbar-brand" to="/"><img src="images/logo.png" class="2xl:w-32 2xl:h-20 w-24 h-14" height="91" width="144"></router-link>
      <button class="navbar-toggler py-2" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span><i style="color: white;" class="fas fa-bars" width="20"></i></span>
      </button>
      <div class="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
        <ul class="navbar-nav ms-md-3">

        </ul>
        <ul class="navbar-nav mb-5 mb-md-0 mt-5 mt-md-0">
          <button class="btn btn-wallet" v-if="address == null" @click="connectMetamask">Connect to Wallet</button>
          <router-link to="/profile" class="btn btn-wallet text-dark" v-if="address != null">My Profile</router-link>
        </ul>
      </div>
    </div>
  </nav>
  <!-- <div :class="this.$route.name !='Mint' ? 'bgPage pb-5' : 'bgMint' " style="min-height: 100vh;"> -->
  <div :class="this.$route.name !='Mint' ? 'bgPage pb-5 min-h-screen' : ''" >
    <router-view />
  </div>
</div>
</template>

<style>
  #app {
    font-family: 'Chakra Petch', sans-serif;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white
  }

  .btn-wallet {
    background: #C9CA03;
    color: black;
    font-size: large;
    font-weight: bold;
    border-radius: 30px;
  }

  .navbar {
    padding-top: 15px;
    padding-bottom: 30px;
    background-image: url('/images/bg_nav.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-size: 20px;
  }

  /* #nav {
  padding: 30px;
} */

  .navbar a {
    font-weight: bold;
    color: white;
  }

  .navbar a:hover {
    font-weight: bold;
    color: grey;
  }

  .bgPage {
    background-image: url('/images/bg_wood.svg');
    background-repeat: repeat;
    background-size: auto;
  }

  .bgMint {
    background: #4B1114
  }

  .navbar a.router-link-exact-active {
    color: #42b983;
  }
</style>
<script>
  import Web3 from 'web3'
  export default {
    name: 'App',
    data: () => ({
      address: null,
      web3: null
    }),
    methods: {
      async connectMetamask() {
        let account = await window.ethereum.request({
          method: 'eth_requestAccounts'
        });
        this.web3 = new Web3(window.ethereum)
        console.log(this.web3)
        this.address = account[0]
        this.$forceUpdate()
      }
    }
  }
</script>